<template>
    <div class="container">
        <div class="row mob-scroll-content py-3 py-xl-0">
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body pointer" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card border-0 mb-4 midNew">
                    <div class="card-body">
                        <h4 class="card-title"><a href="#">Deep Value and Bullish Sentiment in La-Z-Boy</a> <span class="modifyDate">29 July</span></h4>
                        <img src="assets/images/graph.jpg" class="img-responsive" alt="" />
                        <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                            <div class="forexnood d-flex align-items-center"><img src="assets/images/forexnoob.jpg" alt="" /> <span class="mx-2">forexnoob</span> <img src="assets/images/badge.jpg" alt="" /></div>
                            <div class="currency-wrap">
                                <span class="currency"><a href="#">EURUSD</a></span> <span class="monthly"><a href="#">monthly</a></span>
                                <span class="short">
                                    <a href="#" class="redBG"><i data-feather="arrow-down-right"></i> Short</a>
                                </span>
                            </div>
                        </div>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                        <div class="smallbtn d-flex align-items-center"><span class="cardEelement">Technical Analysis</span> <span class="cardEelement">Technical Analysis</span></div>
                        <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                            <div>
                                <span><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                            </div>
                            <div class="righticons">
                                <span><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span> <span><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span> <span><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }, 
}
</script>
