<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive snapTable">
                    <table class="table borderedTable">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Followers</th>
                                <th>Reputation</th>
                                <th>ideas</th>
                                <th align="right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn active">Following</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a @click="dropDown = !dropDown" href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                            <div class="dropdown_menu_animated" :class="dropDown?'show':''">
                                                <li><a href="javascript:void(0)">Remove</a></li>
                                                <li><a href="javascript:void(0)">Block</a></li>
                                            </div>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn active">Following</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn active">Following</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn active">Following</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn">Follow</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn">Follow</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-items-center">
                                        <span class="avtar me-4 active"><img src="assets/images/profile-user.svg" alt="User" title="Forexnoob"></span>
                                        <p class="mb-0 f-16 me-2">Forexnoob</p>
                                        <span class="avtarAward"><img src="assets/images/awardIcon.svg" alt="User" title="Award"></span>
                                    </span>
                                </td>
                                <td>20</td>
                                <td>10</td>
                                <td>40</td>
                                <td>
                                    <span class="d-flex align-items-center justify-content-end">
                                        <a href="javascript:void(0)" class="action-btn greenBtn">Follow</a>
                                        <a href="javascript:void(0)" class="action-btn"><vue-feather type="mail"></vue-feather></a>
                                        <span class="position-relative">
                                            <a href="javascript:void(0)" class="action-btn me-0"><vue-feather type="more-vertical"></vue-feather></a>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dropDown: false,
        }
    },    
}
</script>
