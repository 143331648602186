<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="card-body box-shadow">
                    <div class="activityBlock">
                        <div class="daysAgo d-flex align-items-center mb-3">
                            <span><img src="assets/images/ivanna.png" alt="User" title="Ivanna"></span>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="medium mb-0">Ivanna</h6>
                                <p class="mb-0">7 days ago</p>
                            </div>
                        </div>
                        <div class="row ms-5 me-0 border-bottom trade pb-3 mb-4">
                            <div class="col-12 col-md-5">
                                <img class="w-100" src="assets/images/chart/chartpattern.svg" alt="Chart" title="Profile Chart">
                            </div>
                            <div class="col-12 col-md-7">
                                <h6 class="medium f-18">Deep Value and Bullish Sentiment in La-Z-Boy</h6>
                                <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                                <div class="currency-wrap">
                                    <span class="currency pe-3"><a href="#">EURUSD</a></span>
                                    <span class="monthly pe-3"><a href="#">monthly</a></span>
                                    <span class="short"><a href="#" class="redBG d-inline-flex align-items-center"><vue-feather type="arrow-down-right" ></vue-feather> Short</a></span>
                                </div>
                                <div class="righticons mt-3">
                                    <span class="pe-3 lighttext"><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                                    <span class="pe-3 lighttext"><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span>
                                    <span class="pe-3 lighttext"><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span>
                                    <span class="lighttext"><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="activityBlock">
                        <div class="daysAgo d-flex align-items-center mb-3">
                            <span><img src="assets/images/ivanna.png" alt="User" title="Ivanna"></span>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="medium mb-0">Ivanna</h6>
                                <p class="mb-0">7 days ago</p>
                            </div>
                        </div>
                        <div class="row ms-5 me-0 border-bottom trade pb-3 mb-4">
                            <div class="col-12 col-md-5">
                                <img class="w-100" src="assets/images/chart/chartpattern.svg" alt="Chart" title="Profile Chart">
                            </div>
                            <div class="col-12 col-md-7">
                                <h6 class="medium f-18">EUR/USD  forms a Prospective Head & Shoulders Pattern</h6>
                                <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                                <div class="currency-wrap">
                                    <span class="currency pe-3"><a href="#">EURUSD</a></span>
                                    <span class="monthly pe-3"><a href="#">monthly</a></span>
                                    <span class="short"><a href="#" class="redBG d-inline-flex align-items-center"><vue-feather type="arrow-down-right" ></vue-feather> Short</a></span>
                                </div>
                                <div class="righticons mt-3">
                                    <span class="pe-3 lighttext"><i class="fa fa-heart-o" aria-hidden="true"></i> 14</span>
                                    <span class="pe-3 lighttext"><i class="fa fa-comments-o" aria-hidden="true"></i> 37</span>
                                    <span class="pe-3 lighttext"><i class="fa fa-share-alt" aria-hidden="true"></i> 20</span>
                                    <span class="lighttext"><i class="fa fa-bookmark-o" aria-hidden="true"></i> 10</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="activityBlock">
                        <div class="daysAgo d-flex align-items-center mb-3">
                            <span><img src="assets/images/ivanna.png" alt="User" title="Ivanna"></span>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="medium mb-0">Ivanna</h6>
                                <p class="mb-0">7 days ago</p>
                            </div>
                        </div>
                        <div class="row ms-5 me-0 border-bottom trade pb-4 mb-4">
                            <div class="col-12">
                                <h6 class="medium f-18">Chesapeake Extends Earnings Rally With Ascending Triangle Breakout</h6>
                                <h6 class="medium f-15">Strong Guidance for 2019 Sends Shares Higher</h6>
                                <p>Chesapeake Energy Corporation (CHK) shares rose more than 6% on Friday and extended the stock's rally following Wednesday's better-than-expected earnings. The energy giant reported fourth quarter oil, natural gas and natural gas liquids (NGL) revenue that rose 37.3% to $1.73 billion.</p>
                                <div class="d-flex align-items-center mt-4 imageGallery">
                                    <img width="200" class="me-4" src="assets/images/news/newsblock-2.png" alt="Image" title="Image">
                                    <img width="200" class="me-4" src="assets/images/news/newsblock-3.png" alt="Image" title="Image">
                                    <img width="200" class="" src="assets/images/news/newsblock-4.png" alt="Image" title="Image">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="activityBlock">
                        <div class="daysAgo d-flex align-items-center mb-3">
                            <span><img src="assets/images/ivanna.png" alt="User" title="Ivanna"></span>
                            <div class="flex-grow-1 ms-3">
                                <h6 class="medium mb-0">Ivanna</h6>
                                <p class="mb-0">7 days ago</p>
                            </div>
                        </div>
                        <div class="row ms-5 me-0 border-bottom trade pb-3 mb-4">
                            <div class="col-12">
                                <h6 class="medium f-18">US Non Farm Payroll Data</h6>
                                <p class="lighttext d-flex align-items-center"><vue-feather class="me-1" size="16px" type="clock"></vue-feather> 04:23:28</p>
                                <div class="d-flex align-items-center mt-1 imageGallery">
                                    <p class="me-5 mb-0"><span class="d-block mb-2">Actual</span> <a href="javascript:void(0)" class="btn alert alert-success">Positive</a></p>
                                    <p class="me-5 mb-0"><span class="d-block mb-2">Previous</span> <a href="javascript:void(0)" class="btn alert alert-danger">Negative</a></p>
                                    <p class="mb-0"><span class="d-block mb-2">Consensus</span> <a href="javascript:void(0)" class="btn alert alert-warning">Neutral</a></p>
                                </div>
                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                    <p class="mb-0 medium">Total Votes: 4500</p>
                                    <a href="javascript:void(0)" class="grd_btn">Vote Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="activityBlock loadMore">
                        <div class="row ms-5 me-0 pb-3 mb-4">
                            <div class="col-12">
                               <a href="javascript:void(0)" class="grd_btn">Load More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="card-body box-shadow">
                    <div class="chartAllocation border-bottom pb-3 mb-3">
                        <h6 class="medium f-18 mb-4">Asset Allocation  </h6>
                        <img src="assets/images/chart/allocation.svg" alt="Chart" title="Allocation Chart">
                    </div>
                    <div class="favouriteInstruments">
                        <h6 class="medium f-18 mb-4">Favourite Instruments </h6>
                        <ul class="d-flex align-items-center flex-wrap mb-0">
                            <li><a href="javascript:void(0)" class="gray_btn active">EURUSD</a></li>
                            <li><a href="javascript:void(0)" class="gray_btn">GBPUSD</a></li>
                            <li><a href="javascript:void(0)" class="gray_btn">NASDAQ</a></li>
                            <li><a href="javascript:void(0)" class="gray_btn">BTCUSD</a></li>
                            <li><a href="javascript:void(0)" class="gray_btn">GBPCAD</a></li>
                            <li><a href="javascript:void(0)" class="gray_btn">EURCAD</a></li>
                        </ul>
                        <p>The Australian Dollar also weakened against the Japanese Yen, then trimming most losses as Friday wrapped up. On the daily chart, AUD/JPY left behind and subsequently confirmed</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dropDown: false,
        }
    },    
}
</script>
