<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement profile-banner pb-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6"> 
                        <div class="updateProfile d-flex">
                            <span class="position-relative otherProfileEdit">
                                <img  src="assets/images/ivanna.png" alt="Image" title="Ivanna">
                                <span class="likeCheck position-absolute"><img src="assets/images/checkGreen.svg" alt="Image" title="Likes"></span>
                            </span>
                            
                            <div class="ms-3 flex-grow-1">
                                <h5 class="mb-0">Jezlin Jacob</h5>
                                <p class="lighttext f-12">Joined On : 12 June 2021</p>
                                <div class="likes flex-wrap d-flex align-items-center">
                                    <span class="d-flex align-items-center"><img src="assets/images/likes.png" alt="Icon" title="Like"> 38</span>
                                    <span class="d-flex align-items-center"><img src="assets/images/following.png" alt="Icon" title="Following"> 30</span>
                                    <span class="d-flex align-items-center"><img width="40" src="assets/images/idea.png" alt="Icon" title="Idea"> 160</span>
                                    <span class="d-flex align-items-center"><img width="40" src="assets/images/shareIcon.svg" alt="Icon" title="Share"> 120</span>
                                    <span class="d-flex align-items-center"><img width="40" src="assets/images/award.svg" alt="Icon" title="Award"> 160</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6"> 
                        <div class="d-flex align-items-center justify-content-end equalBtn">
                           <a href="javascript:void(0)" class="gray_btn me-3 d-flex align-items-center"><vue-feather size="16" class="me-1" type="plus"></vue-feather> Follow</a>
                           <a href="javascript:void(0)" class="grd_btn d-flex align-items-center"><vue-feather size="16" class="me-1" type="mail"></vue-feather> Message</a>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <ul class="toplinks pb-2 d-flex align-items-center mb-0">
                            <li class="ps-0"><a @click.prevent="profileTab = 'Activities'" :class="(profileTab == 'Activities')?'active':''" href="javascript:void(0)"> Activities</a></li>
                            <li><a @click.prevent="profileTab = 'Ideas'" :class="(profileTab == 'Ideas')?'active':''" href="javascript:void(0)"> Ideas</a></li>
                            <li><a @click.prevent="profileTab = 'Followers'" :class="(profileTab == 'Followers')?'active':''" href="javascript:void(0)"> Followers</a></li>
                            <li><a @click.prevent="profileTab = 'Following'" :class="(profileTab == 'Following')?'active':''" href="javascript:void(0)"> Following</a></li>
                            <li><a @click.prevent="profileTab = 'Voting'" :class="(profileTab == 'Voting')?'active':''" href="javascript:void(0)"> Voting</a></li>
                            <li><a @click.prevent="profileTab = 'Discussion'" :class="(profileTab == 'Discussion')?'active':''" href="javascript:void(0)"> Discussion</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement activitiesBlock bg-white spaceElement" v-if="profileTab == 'Activities'">
            <activities/>
        </section>
        <section class="blockElement tradeIdeaBlock bg-white spaceElement" v-if="profileTab == 'Ideas'">
            <Ideas/>
        </section>
        <section class="blockElement tradeIdeaBlock bg-white spaceElement" v-if="profileTab == 'Followers'">
            <followers/>
        </section>
        <section class="blockElement tradeIdeaBlock bg-white spaceElement" v-if="profileTab == 'Following'">
            <following/>
        </section>
        <section class="blockElement tradeIdeaBlock bg-white spaceElement" v-if="profileTab == 'Voting'">
            <voting/>
        </section>
        <section class="blockElement tradeIdeaBlock bg-white spaceElement" v-if="profileTab == 'Discussion'">
            <discussion/>
        </section>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import activities from "./other-user-files/activities";
import Ideas from "./other-user-files/ideas";
import followers from "./other-user-files/followers";
import following from "./other-user-files/following";
import voting from "./other-user-files/voting";
import discussion from "./other-user-files/discussion";
export default {
    data() {
        return {
            profileTab: 'Activities',
            dropDown: false,
        }
    },
    components: {
        bannerSlider:bannerslider,
        activities,
        Ideas,
        followers,
        following,
        voting,
        discussion,

    },     
}
</script>
